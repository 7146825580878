var site = site || {};

(function ($) {
  Drupal.behaviors.mppFilterSetV2 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $template = $('.js-mpp-filter-set--v2', context);

      // Append all our categories
      if (!_.isUndefined(site.translations.product_collections)) {
        _.each(site.translations.product_collections, function (obj) {
          // Prevent namespace collision between benefits and categories
          var rendered = site.template.get({
            name: 'filter_set_key',
            data: obj
          });

          $('.js-mpp-filter-set__subfilters .js-mpp-filter-set-rendered--category', $template).append(rendered);
          $('.js-mpp-filter-set__modal .js-mpp-filter-set-rendered--category', $template).append(rendered);
        });
      }

      // Append all our benefits
      if (!_.isUndefined(site.translations.product_skin_concerns)) {
        _.each(site.translations.product_skin_concerns, function (obj) {
          // Prevent namespace collision between benefits and categories
          var rendered = site.template.get({
            name: 'filter_set_key',
            data: obj
          });

          $('.js-mpp-filter-set__subfilters .js-mpp-filter-set-rendered--benefit', $template).append(rendered);
          $('.js-mpp-filter-set__modal .js-mpp-filter-set-rendered--benefit', $template).append(rendered);
        });
      }

      $template.each(function () {
        var $thisTemplate = $(this);
        var $filterBtn = $('.js-mpp-filter-set__button', $thisTemplate);
        var $mobileModal = $('.js-mpp-filter-set__modal', $thisTemplate);
        var $submitBtn = $('.js-mpp-filter-set__submit', $thisTemplate);
        var $mobileBtn = $('.js-mpp-filter-set__mobile-cta', $thisTemplate);
        var $mobilePanel = $('.js-mpp-filter-set__container', $thisTemplate);
        var $closeBtn = $('.js-mpp-filter-set__close', $thisTemplate);
        var $selectionsPanel = $('.js-mpp-filter-set__selections', $thisTemplate);
        var $triggerSets = $('.js-mpp-filter-set-rendered', $thisTemplate);
        var $filterSetSections = $('.js-mpp-filter-set__section', $thisTemplate);
        var $subFilters = $('.js-mpp-filter-set__subfilters', $thisTemplate);
        var $categorySection = $('.js-mpp-filter-set__section--category', $thisTemplate);
        var $benefitSection = $('.js-mpp-filter-set__section--benefit', $thisTemplate);
        var $categorySubfilters = $(
          '.js-mpp-filter-set__subfilters .js-mpp-filter-set-rendered--category',
          $thisTemplate
        );
        var $benefitSubfilters = $(
          '.js-mpp-filter-set__subfilters .js-mpp-filter-set-rendered--benefit',
          $thisTemplate
        );
        var $itemCountContainer = $('.js-mpp-filter-set__count-total', this);
        var $resetBtn = $('.js-mpp-filter-set-reset', context);
        var $mppContainer = $('.js-mpp-container', context);
        var $productGrid = $('.js-product-grid', $mppContainer);
        var $products = $('.js-product-grid-item', $productGrid);
        var $failContainer = $('.js-filter-no-matches', $mppContainer);
        var $mixItUpEl = $productGrid;
        var filterString = $('.mpp-filter-set__reset').data('filter');
        var hasQuickshop = $productGrid.hasClass('product-grid--quickshop');
        var $visibleFilters = $();
        var $availableFilters = $();
        var savedDivTag = [];

        // Loop through all products and add class 'mix'
        $products.each(function () {
          var $thisProduct = $(this);

          $thisProduct.addClass('mix');
          // If not filterable, continue
          if (!$(this).data('benefits').tags) {
            return;
          }

          var data = $(this).data('benefits').tags;
          var newDataBenefit = data.replace(/-/g, '').replace(/\s+/g, '').replace(/\//g, '').toLowerCase();
          // Loop through our tags, split them by comma, then trim the whitespace
          var tags = _.map(newDataBenefit.split(','), _.trim);
          var properTags = _.filter(tags, function (index) {
            if (!_.isEmpty(index)) {
              savedDivTag['.product-grid__item--filter-benefit-' + index] = true;

              return index;
            }
          });

          // Loop through our tags, and add the class to this product item
          _.each(properTags, function (index) {
            $thisProduct.addClass('product-grid__item--filter-benefit-' + index);
          });

          data = $(this).data('categories');
          if (data) {
            // @TODO - have these tags entered in as machine readable in .net
            var newDataCat = data.replace(/-/g, '').replace(/\s+/g, '').replace(/\//g, '').toLowerCase();
            // Loop through our tags, split them by comma, then trim the whitespace
            var categories = _.map(newDataCat.split(','), _.trim);
            var properCategories = _.filter(categories, function (index) {
              if (!_.isEmpty(index)) {
                savedDivTag['.product-grid__item--filter-category-' + index] = true;

                return index;
              }
            });

            // Loop through our tags, and add the class to this product item
            _.each(properCategories, function (index) {
              $thisProduct.addClass('product-grid__item--filter-category-' + index);
            });
          }
        });

        // Loop through our filters and hide any without tags
        $filterBtn.each(function () {
          var key = $(this).data('filter');
          var $me = $(this);

          if (savedDivTag[key] !== true) {
            $me.addClass('hidden');
          }
        });

        $filterSetSections.each(function () {
          var $this = $(this);

          $availableFilters = $this.find($filterBtn);
          if ($availableFilters.length > 0) {
            $visibleFilters = $availableFilters.filter(':not(.hidden)');
          } else {
            // since there are 2 different implementations, we need to check on sibbling parent based on index
            $visibleFilters = $subFilters
              .find('.js-mpp-filter-set-rendered')
              .eq($this.index())
              .find($filterBtn)
              .filter(':not(.hidden)');
          }
          if ($visibleFilters.length < 1) {
            $this.addClass('hidden');
          }
        });

        // Deeplinking support
        function getUrlParameter(name) {
          name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
          var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
          var results = regex.exec(location.search);

          return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }

        var urlParam = getUrlParameter('filter');
        var urlFilter;

        if (urlParam) {
          var queryTag = urlParam.split(',');
          var queryArray = [];

          $.each(queryTag, function (index, tag) {
            var queryFilter = '.product-grid__item--benefit-' + tag;

            $filterBtn.filter('[data-filter="' + queryFilter + '"]').addClass('active');
            queryArray.push(queryFilter);
          });
          urlFilter = queryArray.join(', ');
        }

        // Grabs the filter selectors and joins them
        function filter() {
          var filtersByOr = [];
          var filtersByAnd = [];
          var constructedFilters = [];
          var active_filters_count = 0;

          $filterBtn.filter('.active').each(function () {
            active_filters_count++;
            if ($(this).closest('.js-mpp-filter-set__section--category').length) {
              filtersByOr.push($(this).data('filter'));
            } else {
              filtersByAnd.push($(this).data('filter'));
            }
          });
          filterString = active_filters_count ? filterString : $('.mpp-filter-set__reset').data('filter').toLowerCase();
          _.each(filtersByOr, function (filter) {
            constructedFilters.push(filter + filtersByAnd.join(''));
          });

          if (constructedFilters.length) {
            filterString = constructedFilters.join(', ');
          } else {
            filterString = filtersByAnd.join(', ') || filterString;
          }

          if ($mixItUpEl.mixItUp('isLoaded')) {
            $mixItUpEl.mixItUp('filter', filterString);
            $productGrid.addClass('product-grid--filtered');
            $thisTemplate.addClass('mpp-filter-set--filtered');
          }
        }

        // Show subfilters from the clicked category
        $categorySection.on('click', function (event) {
          event.preventDefault();
          if (!$benefitSubfilters.hasClass('hidden')) {
            $benefitSubfilters.addClass('hidden');
          }
          $categorySubfilters.toggleClass('hidden');
        });

        $benefitSection.on('click', function (event) {
          event.preventDefault();
          if (!$categorySubfilters.hasClass('hidden')) {
            $categorySubfilters.addClass('hidden');
          }
          $benefitSubfilters.toggleClass('hidden');
        });

        // Collect each filter
        $triggerSets.each(function () {
          var $triggers = $('.js-mpp-filter-set__button', this);

          $triggers.on('click', function (event) {
            event.preventDefault();
            $(this).toggleClass('active');
            filter();
            selections();
          });
        });

        // Hide the sibling filter
        $triggerSets.each(function () {
          $(this).on('click', function (event) {
            $(this).siblings('.js-mpp-filter-set__section').removeClass('active');
          });
        });

        // Show selections
        function selections() {
          var category_selectedTags, benefit_selectedTags;

          $('.js-mpp-filter-set__selections-tags').text('');
          $('.js-mpp-filter-set__button.active').each(function () {
            var $activeFilter = $(this);
            var filterData = $activeFilter.attr('data-filter');
            var $filterText = $activeFilter.text();
            var $filterTextSpan = $activeFilter
              .parent('.js-mpp-filter-set-rendered')
              .siblings('.js-mpp-filter-set__selections-tags');

            if (filterData.match(/category/i)) {
              category_selectedTags += $filterText;
              $filterTextSpan.append($filterText + ', ');
            } else if (filterData.match(/benefit/i)) {
              benefit_selectedTags += $filterText;
              $filterTextSpan.append($filterText + ', ');
            }
          });
          $('.js-mpp-filter-set__button').each(function () {
            var $filterSet = $(this);
            var filterData = $filterSet.attr('data-filter');
            var $filterTextSpan = $filterSet
              .parent('.js-mpp-filter-set-rendered')
              .siblings('.js-mpp-filter-set__selections-tags');

            if (
              (!category_selectedTags && filterData.match(/category/i)) ||
              (!benefit_selectedTags && filterData.match(/benefit/i))
            ) {
              $filterTextSpan.text(filterString);
            }
          });
          if ($filterBtn.hasClass('active')) {
            $selectionsPanel.addClass('active').insertAfter($('.mpp-filter'));
          } else {
            $selectionsPanel.removeClass('active');
          }
        }

        // Reset filters
        function reset() {
          $mixItUpEl.mixItUp('filter', 'all');
          $filterBtn.removeClass('active');
          $productGrid.removeClass('product-grid--filtered');
          $selectionsPanel.removeClass('active');
          $thisTemplate.removeClass('mpp-filter-set--filtered');
          $('.js-mpp-filter-set__selections-tags').text(filterString);
        }

        // Close mobile menu
        function close() {
          $mobilePanel.hide();
          $mobileModal.addClass('hidden');
          selections();
        }

        // Trigger filter results
        $submitBtn.on('click', function (event) {
          event.preventDefault();
          // $mixItUpEl.mixItUp('filter', filterString);
          close();
        });

        // Open menu
        $mobileBtn.on('click', function (event) {
          event.preventDefault();
          $mobilePanel.show();
          $mobileModal.removeClass('hidden');
        });

        $resetBtn.on('click', function (event) {
          event.preventDefault();
          reset();
        });

        $closeBtn.on('click', function (event) {
          event.preventDefault();
          close();
        });

        // mixItUp Settings
        // https://github.com/patrickkunka/mixitup/blob/v2/docs/configuration-object.md
        var mixItUpArgs = {
          // animation: {
          //   effects: 'fade'
          // },
          selectors: {
            target: '.js-product-grid-item'
          },
          controls: {
            enable: false
          },
          layout: {
            display: 'block'
          },
          load: {
            filter: urlFilter || 'all'
          }
        };

        // Start mixitup
        $mixItUpEl.addClass('mixitup-initialized').mixItUp(mixItUpArgs);
        // mixItUp Callbacks
        // https://github.com/patrickkunka/mixitup/blob/v2/docs/events.md
        $mixItUpEl
          .on('mixStart', function () {
            // Destroy quickshop
            if (hasQuickshop && Drupal.behaviors.quickshopOverlay) {
              Drupal.behaviors.quickshopOverlay.reset($productGrid);
            }
            // Add our filtered class so .mix does display: none
            $productGrid.addClass('product-grid--filtered');
          })
          .on('mixEnd', function () {
            var $item = $('.js-product-grid-item:visible:not(".product-grid__item--tout")', $mppContainer);

            $failContainer.hide();
            // Display # of filtered results
            $itemCountContainer.text($item.length);
            // Rebuild quickshop
            if (hasQuickshop && Drupal.behaviors.quickshopOverlay) {
              Drupal.behaviors.quickshopOverlay.initializeGrid($productGrid);
            }
          })
          .on('mixFail', function () {
            $failContainer.show();
          })
          .on('mixLoad', function () {
            // Re-init quickshop
            if ($productGrid.length > 0 && hasQuickshop) {
              $productGrid.trigger('product.initializeGrid');
            }
          });
      });
    },
    attached: false
  };
})(jQuery);
